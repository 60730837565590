<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: "MainMenuItem",
        props:{
            basePath: {
                type: String,
                default: ''
            },
            // route object
            item: {
                type: Object,
                required: true
            },
        }
    }
</script>

<style scoped lang="less">

</style>
