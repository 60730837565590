<template>
    <div class="manager-layout">
        <div class="manager-layout-main">
<!--            <Header @help="handleHelp" :helpVisible="helpVisible"/>-->
            <div class="manager-layout-main-wrapper">
                <Aside @change="helpContentTab"/>
                <div class="manager-layout-main-wrapper-content">
                    <router-view/>
                </div>
            </div>
        </div>
        <Help v-model="helpVisible">
            <div slot="helpContent">
                <div class="helpView">
                    <div class="helpView-title">{{helptitle}}</div>
                    <div class="helpView-tips">{{helpText}}</div>
                    <div class="helpView-more" @click="handleClick">{{$t('m.header.more')}}</div>
                </div>
                <div class="helpView">
                    <div class="helpView-title margin" v-for="(item,index) in helpQuest" :key="index">{{item}}</div>
                    <div class="helpView-more" @click="handleClick">{{$t('m.header.helpCenter')}}</div>
                </div>
                <div class="helpView">
                    <div class="helpView-title">{{helpVideoTitle}}</div>
                    <div class="helpView-video"></div>
                </div>
            </div>

        </Help>
    </div>
</template>

<script>
    import Header from "./component/Header"
    import Help from "./component/Help"
    import Aside from "./component/Aside"
    import Config from "../../config/index"
    import {supplementarySettingItem} from "../../request/api/merchant";
    export default {
        name: "index",
        components: {
            Header,
            Help,
            Aside
        },
        data(){
            return {
                helpVisible:false,
                helptitle:"",
                helpText:"",
                helpQuest:[],
                helpVideoTitle:"",
                helpActive:'',
                helpMsgList:[
                    {
                        key:"dashboard",
                        helptitle:"如何发布小程序？",
                        helpText:"在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试",
                        helpQuest:['如何发布小程序？','如何发布小程序？','如何发布小程序？'],
                        helpVideoTitle:"如何发布小程序？",
                        action:"oamvd1",

                    },{
                        key:"shop",
                        helptitle:"如何发布小程序？",
                        helpText:"在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试",
                        helpQuest:['如何发布小程序？','如何发布小程序？','如何发布小程序？'],
                        helpVideoTitle:"如何发布小程序？",
                        action:"raamn1",
                    },{
                        key:"goods",
                        helptitle:"如何添加商品？",
                        helpText:"添加商品分为两步，第一步是添加商品的基本信息， 第二步添加商品的",
                        helpQuest:['如何添加商品分组？','如何使用商品模板？'],
                        helpVideoTitle:"如何添加商品？",
                        action:"pkvxwk",
                    },{
                        key:"article",
                        helptitle:"如何添加文章？",
                        helpText:"添加文章分为两步，第一步是添加文章的基本信息， 第二步添加文章的",
                        helpQuest:['如何添加文章分组？','如何使用文章模板？'],
                        helpVideoTitle:"如何添加文章？",
                        action:"dt43z4",
                    },{
                        key:"customer",
                        helptitle:"如何进行客户管理？",
                        helpText:"客户管理主要是客户查询和客户信息查询，客户信息查询主要是客户的基",
                        helpQuest:['如何设计一款优秀的小程序？','如何将店铺或者商品分享到朋友圈？','买家如何进入我的店铺？'],
                        helpVideoTitle:"如何搭建店铺主页？",
                        action:"gk5rds",
                    },{
                        key:"order",
                        helptitle:"如何管理订单？",
                        helpText:"订单管理主要分为订单查询、订单处理和售后处理。",
                        helpQuest:['如何发货？','如何处理售后？'],
                        helpVideoTitle:"如何处理售后？",
                        action:'th3czx',
                    },{
                        key:"channel",
                        helptitle:"什么是渠道？",
                        helpText:"渠道是指小程序分发的方式，通过渠道管理可以了解各个渠道分发的效果",
                        helpQuest:['如何使用渠道？','如何统计渠道分发的效果？'],
                        helpVideoTitle:"如何使用渠道？",
                        action:"cxty0p",
                    },{
                        key:"communion",
                        helptitle:"如何使用表单？",
                        helpText:"表单的主要作用是收集用户的信息，应用在问卷调查、活动报名等场景",
                        helpQuest:['如何设计表单？','如何管理评价?','查询管理是干什么用的？'],
                        helpVideoTitle:"如何创建表单？",
                        action:"kkhuma",
                    },{
                        key:"coupon",
                        helptitle:"如何使用优惠券？",
                        helpText:"优惠券在使用之前，需要先创建，目前系统暂只支持代金券。代金券新增",
                        helpQuest:['如何设计一款优秀的小程序？','如何将店铺或者商品分享到朋友圈？','买家如何进入我的店铺？'],
                        helpVideoTitle:"如何创建及使用优惠券？",
                        action:"fxt3ob",
                    },{
                        key:"setting",
                        helptitle:"如何设置退货地址？",
                        helpText:"发布小程序分为两种情况：1、您已注册小程序  2、您还未注册小程序？",
                        helpQuest:['如何设置邮费？','如何设置商品展示页的内容？','如何设置客服？'],
                        helpVideoTitle:"如何进行交易设置？",
                        action:"angvxo",
                    }
                ],
            }
        },
        mounted() {
            // this.SettingItem()
        },
        methods:{
            //内容修改
            helpContentTab(){
                let currtPath = this.$route.path
                this.helpMsgList.forEach(item=>{
                    if(currtPath.indexOf(item.key)>-1){
                        this.helptitle = item.helptitle
                        this.helpText = item.helpText
                        this.helpQuest = item.helpQuest
                        this.helpVideoTitle = item.helpVideoTitle
                        this.helpActive = item.action
                    }
                })
            },
            handleHelp(){
                this.helpVisible = !this.helpVisible
                this.helpContentTab()
            },
        //帮助中心跳转
            handleClick(){
                window.open(Config.helpUrl+this.helpActive);
            },
            SettingItem(){
                supplementarySettingItem({
                    miniProId:8
                }).then(res=>{
                    console.log(res)
                })
            },
        }
    }
</script>


<style scoped lang="less">
    @import "../../assets/style/index";
    .manager-layout{
        background: #eee;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        &-main{
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 100%;
            &-wrapper{
                flex: 1;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                &-content{
                    flex: 1;
                    padding: 16px 24px;
                    box-sizing: border-box;
                    overflow: hidden;
                }
            }
        }
    }
    .helpView{
        padding: 24px 0 16px;
        border-bottom: 1px solid #FAFAFA;
        .helpView-title{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .helpView-tips{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 9px;
        }
        .helpView-more{
            cursor: pointer;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: @color-primary;
            margin-top: 10px;
        }
        .margin{
            margin-top: 6px;
        }
        .helpView-video{
            width: 100%;
            height: 104px;
            background: #D8D8D8;
            margin-top: 8px;
        }
    }
</style>
