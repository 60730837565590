<template>
    <div class="manager-layout-main-wrapper-aside">
        <div class="aside-main">
            <div v-for="(item,index) in MainMenus"
                 :class="['aside-item',{active:isActive(item)}]"
                 :key="index"
                 @click="clickMainItem(item,index)">
                    <cz-svg-icon class="icon" :name="item.meta.icon"/>
                    <div>{{item.meta.title}}</div>
            </div>
            <div class="fabu" @click="release">发布</div>
            <div class="loginOut" @click="logout">退出</div>
        </div>
        <div class="aside-secondary" :style="{width:`${secondaryOpen?secondaryWidth:0}px`}">
            <div class="aside-secondary-wrapper" :style="{width:`${secondaryWidth}px`}">
                <el-menu :router="true" :default-active="defaultActive" :default-openeds="defaultOpeneds">
                    <template v-for="(menu,i) in SecondaryMenus">
                        <el-submenu :index="`${i}`">
                            <template slot="title">
                                {{menu.meta.title}}
                            </template>
                            <template v-for="(m,j) in menu.children">
                                <el-menu-item v-if="m.meta && m.meta.isNav"
                                              :index="`${i}-${j}`"
                                              :route="m.path">
                                    {{m.meta.title}}
                                </el-menu-item>
                            </template>
                        </el-submenu>
                    </template>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
    import path from 'path'
    import Routers from "../../../router/router"
    import MainMenuItem from "./component/MainMenuItem";
    import {logout} from "../../../request/api/account";
    import {getTemplateDraftList,
        addToTemplate,
        initCodeTemplate} from "../../../request/api/wechat";
    export default {
        name: "Aside",
        components:{MainMenuItem},
        data(){
            return {
                secondaryWidth:180,
                MainMenus:[],
                basePath:'/manager',
                releaseShow:false,
            }
        },
        computed:{
            //通过当前path遍历所有的router,并且返回二级路由
            SecondaryMenus(){
                let path = this.$route.path;
                for(let i = 0;i < this.MainMenus.length;i ++){
                    let c0 = this.MainMenus[i];
                    if (c0.path === path) return c0.children;

                    if (c0.children){
                        for (let j = 0;j < c0.children.length;j ++){
                            let c1 = c0.children[j];
                            if (c1.path === path) return c0.children;

                            if (c1.children) {
                                for (let k = 0; k < c1.children.length; k++) {
                                    let c2 = c1.children[k];
                                    if (c2.path === path) return c0.children
                                }
                            }
                        }
                    }
                }
                return [];
            },

            //默认展开所有二级导航
            defaultOpeneds(){
                let opened = [];
                if (!this.SecondaryMenus) return opened;
                this.SecondaryMenus.forEach((m,i)=>opened.push(`${i}`));
                return opened;
            },
            //默认选中某个三级导航菜单
            defaultActive(){
                if (!this.SecondaryMenus) return '';
                let path = this.$route.path;
                for(let i = 0;i < this.SecondaryMenus.length;i ++){
                    let menu = this.SecondaryMenus[i];
                    for (let j = 0;j < menu.children.length;j ++){
                        let children = menu.children[j];
                        if (children.path === path){
                            return `${i}-${j}`
                        }
                    }
                }
                return '';
            },

            //判断二级导航组件是否展开
            secondaryOpen(){
                let path = this.$route.path;
                let isMain = this.MainMenus.findIndex(menu=>menu.path === path);
                return isMain === -1;
            },
        },
        mounted() {
            console.log(document.domain)
            if(document.domain&&document.domain=='localhost'&&document.domain=='192.168.100.104'){
                this.releaseShow = true
            //    且请求200
            }
            let currentRouter = Routers.find(r=>r.path === this.basePath);
            this.MainMenus = currentRouter.children.filter(r=>r.meta && r.meta.isNav);
        },
        methods:{
            clickMainItem(item,index){
                if (!this.isActive(item)){
                    this.$router.push(this.resolvePath(item.path));
                }
                this.$emit('change')
            },

            //一级路由是否被选中
            isActive(item){
                return this.$route.path.startsWith(this.resolvePath(item.path));
            },
            resolvePath(routePath) {
              return path.resolve(this.basePath,routePath)
            },
            //发布
            release(){
                getTemplateDraftList().then(res=>{
                    if(res.code==200){
                        let templateDraftList = res.data
                        addToTemplate({
                            draftId:templateDraftList[0].draftId
                        }).then(addRes=>{
                            if(addRes.code==200){
                                initCodeTemplate().then(resp=>{
                                    if(resp.code==200){
                                        this.$message.success('发布成功')
                                    }else{
                                        this.$message.error('初始化微信模板：'+resp.message)
                                    }
                                })
                            }else{
                                this.$message.error('微信草稿模板添加到代码模板库：'+addRes.message)
                            }
                        })
                    }else{
                        this.$message.error('获取微信代码模板列表：'+res.message)
                    }
                })
            },
            //退出
            logout(){
                let _this = this
                _this.$Cache.clear();
                _this.$router.replace('/login');
                _this.$message.success(_this.$t('header.exitSuccessful'))
            },

        }
    }
</script>

<style scoped lang="less">
    @import '../../../assets/style/index';
    .manager-layout-main-wrapper-aside{
        flex-shrink: 0;
        height: 100%;
        user-select: none;
        display: flex;
        flex-direction: row;
        position: relative;
        .aside-main{
            height: 100%;
            background: #09121a;
            width: 72px;
            overflow-y: scroll;
            .hidden-scroll-bar;
            font-size: 14px;
            .fabu{
                position: absolute;
                color: rgba(174, 174, 174, 100);
                font-size: 14px;
                cursor: pointer;
                width: 72px;
                text-align: center;
                bottom: 80px;
                &:hover{
                    color: @color-primary!important;
                }
            }
            .loginOut{
                position: absolute;
                color: rgba(174, 174, 174, 100);
                font-size: 14px;
                cursor: pointer;
                width: 72px;
                text-align: center;
                bottom: 30px;
                &:hover{
                    color: @color-primary!important;
                }
            }
            .aside-item{
                width: 100%;
                height: 72px;
                .flex-center;
                flex-direction: column;
                color: white;
                cursor: pointer;
                position: relative;
                transition: .15s;
                opacity: 0.7;

                .icon{
                    width: 24px;
                    height: 24px;
                    margin-bottom: 7px;
                }

                &:after{
                    display: block;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 2px;
                    background: transparent;
                    transition: .15s;
                }

                &.active{
                    background: @color-company-hover;
                    color: @color-primary;
                    opacity: 1;
                    &:after{
                        background: @color-primary;
                    }
                }

                &:not(.active):hover{
                    .active;
                }
            }
        }

        .aside-secondary{
            background: white;
            overflow: hidden;
            transition: .15s;
            position: relative;
            &-wrapper{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 180px;
                overflow-y: scroll;
                .hidden-scroll-bar;
                .el-menu{
                    border-right: none;
                }
            }
        }
    }
</style>
