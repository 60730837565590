<template>
    <div class="manager-layout-main-header">
        <div class="header-wrapper">
            <el-tooltip class="item" effect="dark" :content="$t('m.returnToEnterpriseCenter')" placement="bottom">
                <div class="header-button" @click="clickNotice">
                    <cz-svg-icon class="icon" name="left-arrow"/>
                </div>
            </el-tooltip>
            <div class="header-button change-button" @click="toEdit">
                <cz-svg-icon class="icon" name="header-icon-change"/>
                <div>{{this.$t('m.header.toMaking')}}</div>
            </div>
        </div>
        <div class="header-wrapper">
            <div class="header-button help-button" @click="helpAction">
                <div>{{this.$t('m.header.help')}}</div>
                <cz-svg-icon class="icon" :name="helpVisible?'header-help-close':'header-help-open'"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        props:{
            helpVisible:Boolean
        },

        methods:{
            toEdit(){
                this.$router.replace("/making")
            },
            helpAction(){
                this.$emit('help');
            },
            clickNotice(){
                this.$router.replace("/edit/myMiniPro")
            },
        }
    }
</script>

<style scoped lang="less">
    @import '../../../assets/style/index';

    .manager-layout-main-header{
        height: 40px;
        background: white;
        flex-shrink: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .header-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            white-space: nowrap;
            .header-button{
                .flex-center;
                cursor: pointer;
                box-sizing: border-box;
                min-height: 36px;
                flex-wrap: nowrap;
                margin: 0 2px;
                padding: 0 10px;
                user-select: none;

                &:hover{
                    background: @color-button-hover;
                    border-radius: @border-radius-sm;
                    color: @color-primary;
                }

                .icon{
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                }

                &.help-button{
                    .icon{
                        margin-left: 4px;
                    }
                }
                &.change-button{
                    .icon{
                        margin-right: 4px;
                    }

                    position: relative;
                    &:after{
                        position: absolute;
                        content: "";
                        display: block;
                        top: 14px;
                        width: 100%;
                        height: 12px;
                        border-left: 1px solid #F8F6F5;
                    }
                }
            }
        }
    }
</style>
