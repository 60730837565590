<template>
<!--    客服-->
    <el-popover
            :placement="placement"
            width="100"
            popper-class="popperWapper"
            :trigger="trigger">
        <slot slot="reference" name="btnText"></slot>
        <div class="content">
            <div>{{$t('services.header.saomazixun')}}</div>
            <img src="../../../assets/manager-imgs/customerCode.png"/>
        </div>
    </el-popover>
</template>

<script>
    export default {
        name: "index",
        props:{
            placement:String,
            trigger:{
                type:String,
                default:"click"
            }
        }
    }
</script>

<style scoped lang="less">
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 40px;
        font-size: 14px;
        color: #333;
        width: 100px;
        text-align: center;
        img{
            width: 100px;
            height: 100px;
        }
    }

</style>