<template>
    <div class="manager-layout-help" :style="{width: `${value?230:0}px`}">
        <div class="help-content">
            <i class="el-icon-close close" @click="closeAction"/>
            <div class="title">{{this.$t('m.header.help')}}</div>
            <div class="help-customer">
                <div class="help-customer-img">
                    <img src="../../../assets/manager-imgs/customer-icon.png">
                </div>
                <div class="help-customer-text">
                    <czCustomerService placement="left" trigger="hover">
                        <div class="help-customer-title" slot="btnText">{{this.$t('m.header.custimer')}}</div>
                    </czCustomerService>
                    <div class="help-customer-tips">{{this.$t('m.header.time')}}</div>
                    <div class="help-customer-phone" @click="handleCall">{{this.$t('m.header.phone')}}<span>4000-570-022</span></div>
                </div>
            </div>
            <div class="help-search">
                <el-input :placeholder="this.$t('m.header.placeholder')" prefix-icon="el-icon-search" size="small" @focus="goHelp"></el-input>
            </div>
            <div>
                <slot name="helpContent"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import czCustomerService from "../../../components/common/cz-customer-service";
    import Config from "../../../config/index"
    export default {
        name: "Help",
        props:{
            value:Boolean
        },
        components:{
            czCustomerService
        },
        methods:{
            closeAction(){
                this.$emit('input',false);
                this.$emit('close',false);
            },
        //    拨打电话
            handleCall(){
                window.location.href = 'tel:4000-570-022';
            },
        //    搜索帮助
            goHelp(){
                window.open(Config.officialUrl+'/#/support');
            },
        }
    }
</script>

<style scoped lang="less">
    @import '../../../assets/style/index';
    .manager-layout-help{
        width: 230px;
        height: 100%;
        background: white;
        margin-left: 1px;
        transition: .1s;
        overflow: hidden;
        position: relative;
        .help-content{
            overflow-y: scroll;
            .hidden-scroll-bar;
            padding: 10px 16px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            width: 230px;
            height: 100%;
            .title{
                color: #333;
                font-size: 16px;
                font-weight: @font-weight-semibold;
            }
            .close{
                position: absolute;
                width: 20px;
                height: 20px;
                padding: 5px;
                top: 14px;
                right: 14px;
                cursor: pointer;
            }
        }
        .help-customer{
            border-bottom: 1px solid #FAFAFA;
            display: flex;
            margin-top: 32px;
            padding-bottom: 16px;
            box-sizing: border-box;
            .help-customer-img{
                width: 40px;
                height: 40px;
                border-radius: 50% 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 111, 51, 0.05);
                img{
                    width: 24px;
                    height: 24px;
                }
            }
            .help-customer-text{
                margin-left: 12px;
                .help-customer-title{
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: @color-primary;
                    line-height: 20px;
                    cursor: pointer;
                }
                .help-customer-tips{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                    margin-top: 6px;
                }
                .help-customer-phone{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                    margin-top: 2px;
                    cursor: pointer;
                    span{
                        color: #333333;
                    }
                }
            }
        }
        .help-search{
            margin-top: 16px;
        }
    }
</style>
